<!--
 * @Author       : JiangChao
 * @Date         : 2024-04-21 13:06:27
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-09-16 22:37:17
 * @Description  : 新增组织弹窗
-->
<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    width="50%"
    :append-to-body="true"

  >
    <template slot="title">
      <div class="font-heavy" style="font-size: 18px;color: #303133">
        {{ $t("add_organizations") }}
      </div>
    </template>
    <el-form :model="addForm" status-icon ref="resetRef" label-width="100px">
      <el-form-item label-width="0px">
        <div>{{ $t("organ_name") }}</div>
        <el-input
          clearable
          v-model="addForm.name"
          autocomplete="off"
          :placeholder="$t('please_enter')"
        >
        </el-input>
      </el-form-item>
      <el-form-item label-width="0px">
        <div>{{ $t("note") }}</div>
        <el-input
          clearable
          v-model="addForm.remark"
          autocomplete="off"
          :placeholder="$t('please_enter')"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">{{
        $t("cancel")
      }}</el-button>
      <el-button type="primary" @click="handelOk">{{
        $t("confirm")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { orgSaveApi } from "@/api/api.js";

export default {
  name: "",
  data() {
    return {
      addForm: {},
      dialogFormVisible: false,
    };
  },
  methods: {
    showDialog() {
      this.dialogFormVisible = true;
      this.addForm = {};
    },

    handelOk() {
      let params = {
        ...this.addForm,
        orgId: "",
        owner: "",
      };
      orgSaveApi(params).then((r) => {
        console.log("=====", r);
        this.$message.success(this.$t("add_organization_success"));

        this.dialogFormVisible = false;
        this.$emit("ok");
      });
    },
  },
  props: {},
  components: {},
  computed: {
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },

  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },
  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
  },
  created() {},
};
</script>

<style scoped lang="less"></style>
